.dropzone {
  padding: 20px;
  border-width: 1px;
  display: flex;
  justify-content: center;
  border-color: #0073bb;
  border-style: dashed;
  background-color: #ffffff;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone:focus {
  border-color: #0073bb;
}

.dropzone.disabled {
  opacity: 0.6;
}
