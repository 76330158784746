.awsui-util-action-stripe-group {
  box-sizing: border-box;
  width: auto;
  margin-left: 0;
  padding: 0.5rem;
}

.awsui-key-label {
  color: var(--awsui-color-text-label, #545b64);
  font-weight: 700;
  margin-bottom: 2px;
}

[data-css="disable-container-box-shadow"]::after {
  box-shadow: none !important;
}

.awsui-child-vertical {
  margin-bottom: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_flashbar_1q84n_1denr_295 {
  padding-top: 27px;
}

:root {
  --font-family-base-mylvzb: "open-san", "Noto Sans", "Helvetica Neue", Roboto,
    Arial, sans-serif !important;
}

.white_background {
  background-color: var(--color-background-layout-panel-content-r9pdjh, #fff);
  box-shadow: var(
    --shadow-panel-v9vx7i,
    0 1px 1px 0 rgba(0, 28, 36, 0.3),
    1px 1px 1px 0 rgba(0, 28, 36, 0.15),
    -1px 1px 1px 0 rgba(0, 28, 36, 0.15)
  );
}
