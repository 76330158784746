.pad-top {
  padding-top: 40px !important;
}
.awsui_navigation_hyvsj_n9r5c_584 {
  box-shadow: none !important;
}

.awsui_hide-navigation_hyvsj_n9r5c_699 {
  display: none;
}

.awsui_tools-toggle_1fj9k_avnjw_41 {
  display: none;
}
