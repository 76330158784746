.badge {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-collapse: separate;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-sizing: border-box;
    caption-side: top;
    cursor: auto;
    direction: ltr;
    display: inline-block;
    font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
    font-size: 12px;
    font-stretch: 100%;
    font-weight: 400;
    height: 20px;
    letter-spacing: 0.06px;
    line-height: 20px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 0px;
    tab-size: 8;
    text-align: left;
    text-indent: 0px;
    text-shadow: none;
    text-wrap: wrap;
}
