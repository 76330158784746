#no-provider-selected {
  padding: 0px 12px;
}

[data-css="disable-container-box-shadow"]::after {
  box-shadow: none !important;
}

.awsui-context-top-navigation {
  position: sticky;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* 100% of the viewport height */
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: lightblue;
  padding: 10px;
  z-index: 1000;
}

.content {
  flex: 1;
  overflow-y: auto; /* Add scroll if content exceeds the available height */
}

.custom-main-header {
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1009;
  margin: 0;
}
