.container {
    display: flex !important;
    height: 100vh !important;
  }
  
.section {
    flex: 1 !important;
    overflow-y: auto !important;
}

  